<template>
  <section class="basic-textarea">
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="!tableReady"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <table
                id="ReportList"
                class="dataTable dt-responsive table w-100"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("report_table.name") }}</th>
                    <th>{{ $t("report_table.group") }}</th>
                    <th>{{ $t("report_table.type") }}</th>
                    <th>{{ $t("report_table.database") }}</th>
                    <th>{{ $t("dataTable.create_user") }}</th>
                    <th>{{ $t("dataTable.create_date") }}</th>
                    <th>{{ $t("dataTable.update_user") }}</th>
                    <th>{{ $t("dataTable.update_date") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData" :key="item.report_m_id">
                    <td class="text-rigth">{{ item.report_m_id }}</td>
                    <td>{{ item.report_name }}</td>
                    <td>{{ item.report_group }}</td>
                    <td>{{ item.report_type }}</td>
                    <td>{{ item.database }}</td>
                    <td>{{ item.create_user }}</td>
                    <td :data-order="item.create_date_timestamp">
                      {{ item.create_date_convert }}
                    </td>
                    <td>{{ item.update_user }}</td>
                    <td :data-order="item.update_date_timestamp">
                      {{ item.update_date_convert }}
                    </td>
                    <td class="text-center">
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template v-slot:button-content>
                            <i class="far fa-ellipsis-v processes-icon"></i>
                          </template>
                          <b-link
                            v-if="$can('manage', '/Report/Display')"
                            :to="{
                              name: '/Report/Display',
                              params: { id: item.report_m_id },
                            }"
                            target="_blank"
                            class="dropdown-item"
                          >
                            <i
                              class="fal fa-info-circle font-small-4 mr-50"
                            ></i>
                            {{ $t("Display") }}
                          </b-link>
                          <b-link
                            v-if="$can('manage', '/Report/UpdateReport')"
                            :to="{
                              name: '/Report/Edit',
                              params: { id: item.report_m_id },
                            }"
                            target="_blank"
                            class="dropdown-item"
                          >
                            <i class="fal fa-edit font-small-4 mr-50"></i>
                            {{ $t("Edit") }}
                          </b-link>
                          <a
                            v-if="$can('manage', '/Report/DeleteReport')"
                            class="dropdown-item"
                            disabled
                          >
                            <i
                              class="fal fa-times-circle font-small-4 mr-50"
                            ></i>
                            {{ $t("Delete") }}
                          </a>
                        </b-dropdown>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import { BDropdown, BDropdownItem, BLink, BOverlay } from "bootstrap-vue";
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";
import { mapGetters } from "vuex";
import { GET_ALL_REPORTS } from "@/store/services/report-service";

export default {
  name: "report_list",
  components: {
    BDropdown,
    BDropdownItem,
    BLink,
    BOverlay,
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false,
    };
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
  created() {
    this.$store.dispatch(GET_ALL_REPORTS).then((response) => {
      if (response.status) {
        this.tableData = response.data;
        this.$nextTick(() => {
          this.createTable();
        });
      }
    });
  },
  methods: {
    createTable() {
      this.tableReady = false;
      this.table = $("#ReportList").DataTable({
        columnDefs: [
          { orderable: false, targets: [9] },
          { type: "num", targets: [0, 6, 8] },
        ],
        scrollX: true,
        scrollY: "1000px",
        scrollCollapse: true,
        buttons: [
          {
            extend: "colvis",
            text: this.$t("dataTable.show_hide"),
          },
          {
            extend: "pdf",
          },
          {
            extend: "excel",
            title: this.$t("report_list.file_name"),
            filename: this.$t("report_list.file_name"),
          },
        ],
        language: {
          url:
            this.getLocale == "tr-TR"
              ? "//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json"
              : "//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json",
        },
      });
      this.tableReady = true;
    },
  },
  watch: {
    getLocale() {
      Promise.all([this.table.destroy()]).then(this.createTable);
    },
  },
};
</script>
